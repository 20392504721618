import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { alertError, alertSuccess } from '@toast'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      alertError,
      alertSuccess,
      options: [],
      optionsEmail: [],
      id_affiliator: 0,
      email_name: '',
      fullName: '',
      phoneNumber: '',
      city_name: '',
      photo_upload: null,
      isPhotoOke: false,
      photo_name: '',
      skill_set: '',
      isWhatsapp: '',
      isNotWhatsapp: '',
      isErrWhatsapp: '',
      isIconTrue: false,
      isIconFalse: false,
      isMsgPhoto: '',
      isErrPhoto: false,
      fullOptions: [],
      fillForm: [],
      err_socmed: '',
      isSocMedValid: false,
      isErrorEmail: false,
    }
  },
  watch: {
    phoneNumber(newVal, old) {
      this.debounceWhatsapp(newVal)
    },
    'fillForm.fb_account': 'validateSocialMedia',
    'fillForm.tw_account': 'validateSocialMedia',
    'fillForm.ig_account': 'validateSocialMedia',
    'fillForm.tg_account': 'validateSocialMedia',
    'fillForm.tk_account': 'validateSocialMedia',
    email_name(val, oldVal) {
      if (val !== '') {
        this.isErrorEmail = false

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
        this.isErrorEmail = !emailRegex.test(val)
        if (this.isErrorEmail === true) {
          this.$toast_error({
            message: 'Email harus berupa alamat surel yang benar',
          })
        }
      } else {
        this.isErrorEmail = true
      }
    },
  },
  methods: {
    handleInput(val) {
      const res = this.fullOptions.filter(el => el.email === val)
      if (res.length) {
        this.phoneNumber = res[0].phone_number.substring(1)
        this.email_name = res[0].email
        this.fullName = res[0].name
        this.id_affiliator = res[0].id
      }
    },
    backToHome() {
      window.history.go(-1)
    },
    async getCity(value) {
      const url = '/v1/register/search-city'
      await affiliateAxiosIns
        .get(url, {
          params: {
            search: value,
          },
        })
        .then(res => {
          const { data } = res.data
          this.options = data.map(city => ({
            label: `${city.city_name}, ${city.province_name}`,
            value: city.city_name,
          }))
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failed',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            { timeout: 3000 },
          )
        })
    },
    debounceCity(value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.getCity(value)
      }, 1000)
    },
    cekWhatsapp(value) {
      const no = 62 + value
      if (no.length >= 9) {
        const url = '/v1/pumkm/admin/check-is-wa'
        affiliateAxiosIns
          .get(url, {
            params: {
              phone_no: no,
            },
          })
          .then(res => {
            const { data } = res
            if (data.status === 'success') {
              this.isWhatsapp = 'Nomor terhubung WhatsApp'
              this.isNotWhatsapp = ''
              this.isErrWhatsapp = ''
              this.isIconTrue = true
              this.isIconFalse = false
            }
          })
          .catch(err => {
            this.isWhatsapp = ''
            this.isErrWhatsapp = ''
            this.isNotWhatsapp = 'Nomor tidak terhubung WhatsApp'
            this.isIconTrue = false
            this.isIconFalse = true
          })
      } else if (no.length < 9) {
        this.isNotWhatsapp = ''
        this.isWhatsapp = ''
        this.isIconTrue = false
        this.isIconFalse = false
        this.isErrWhatsapp = 'Nomor hp kurang dari 9'
      } else if (no.length === 0) {
        this.isWhatsapp = ''
        this.isNotWhatsapp = ''
        this.isErrWhatsapp = ''
        this.isIconTrue = false
        this.isIconFalse = false
      }
    },
    debounceWhatsapp(value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        if (value.length <= 13) {
          this.cekWhatsapp(value)
        } else if (value.length >= 13) {
          this.phoneNumber = this.phoneNumber.slice(0, 12)
        }
      }, 1000)
    },
    async getEmail(value) {
      const url = '/v1/pumkm/admin/search-by-email'
      await affiliateAxiosIns
        .get(url, {
          params: {
            search: value,
          },
        })
        .then(res => {
          const { data } = res.data
          this.fullOptions = data
          this.optionsEmail = this.fullOptions.map(i => i.email)
        })
    },
    debounceEmail(value) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.getEmail(value)
        this.cekEmail(value)
      }, 1000)
    },
    validateExt(evChange) {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text:
              'Hanya dapat upload file dengan ekstensi JPG, PNG, JPEG and GIF',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-err')
          errorSpan.classList.add('err-ok')
        }
        this.$refs.photoFile.files = null
        this.photo_upload = null
      } else {
        this.validateSize()
      }
    },
    validateSize() {
      const maxSizeInBytes = 1024 * 1024
      const fileSize = this.$refs.photoFile.files[0]?.size || 0
      if (fileSize > maxSizeInBytes) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Galat',
            text: 'File tidak boleh lebih dari 1Mb',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-ok')
          errorSpan.classList.add('err-err')
        }
        this.$refs.photoFile.files = null
        this.photo_upload = null
        this.isPhotoOke = true
        return false
      }
      const errorSpan = document.querySelector('span[res="err-mess"]')
      if (errorSpan) {
        errorSpan.classList.remove('err-err')
        errorSpan.classList.add('err-ok')
      }
      this.onFileInputChange()
      return true
    },
    onFileInputChange() {
      const fileInput = this.$refs.photoFile.files
      if (fileInput.length > 0) {
        this.photo_upload = fileInput[0].name
        this.isPhotoOke = false
        const errorSpan = document.querySelector('span[res="err-mess"]')
        if (errorSpan) {
          errorSpan.classList.remove('err-err')
          errorSpan.classList.add('err-ok')
        }
      } else {
        this.$refs.photoFile.files = null
        this.photo_upload = null
        this.isPhotoOke = true
      }
    },
    validateSocialMedia() {
      const filledAccounts = [
        this.fillForm.fb_account,
        this.fillForm.tw_account,
        this.fillForm.ig_account,
        this.fillForm.tg_account,
        this.fillForm.tk_account,
      ]

      const isAnyAccountFilled = filledAccounts.some(account => account !== '')
      this.err_socmed = isAnyAccountFilled
        ? ''
        : '*Setidaknya isi Satu Sosial Media'
      this.isSocMedValid = isAnyAccountFilled
    },
    showModalKonfirmasi() {
      this.$bvModal.show('modal-konfirmasi')
    },
    hideModalKonfirmasi() {
      this.$refs.modalKonfirmasi.hide()
    },
    async handleSubmit() {
      this.validateSocialMedia()
      const url = '/v1/pumkm/admin/insert-pumkm'
      const phone = 0 + this.phoneNumber
      const formData = new FormData()
      formData.append('id_affiliator', this.id_affiliator)
      formData.append('email', this.email_name)
      formData.append('full_name', this.fullName)
      formData.append('phone_no', phone)
      formData.append('city', this.city_name.label)
      formData.append(
        'photo',
        this.$refs.photoFile.files[0] === undefined
          ? ''
          : this.$refs.photoFile.files[0],
      )
      formData.append('fb', this.fillForm.fb_account || '')
      formData.append('tw', this.fillForm.tw_account || '')
      formData.append('ig', this.fillForm.ig_account || '')
      formData.append('tg', this.fillForm.tg_account || '')
      formData.append('ttk', this.fillForm.tk_account || '')
      formData.append('skill', this.skill_set)
      await affiliateAxiosIns
        .post(url, formData)
        .then(res => {
          this.$refs.modalKonfirmasi.hide()
          const text = 'Data PUMKM yang anda masukkan Berhasil'
          this.alertSuccess(text)
          this.backToHome()
        })
        .catch(err => {
          const text = 'Data PUMKM yang anda masukkan Gagal'
          this.alertError(text)
        })
    },
  },
}
